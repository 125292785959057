import axios from "axios";
import axiosRetry from "axios-retry";
import React, { ChangeEvent, FormEvent, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import ErrorMessage from "../error-message/error-message";
import FullScreenPromiseTracker from "../promise-tracker/full-screen-tracker";
import RecaptchaPopout from "../recaptcha-popout/recaptcha-popout";

interface propTypes {
   eventName?: string,
   eventImage?: string,
   title?: string,
   subheading?: string,
   eventPlaceholder?: string
}

interface enquiryProps {
   first_name: string,
   last_name: string,
   email_address: string,
   number_of_guests: number | "",
   telephone_number: string,
   package_name: string,
   company?: string,
   message: string,
   terms_agreement: boolean,
   marketing_agreement: boolean
}

interface errorProps {
   first_name?: boolean,
   last_name?: boolean,
   email_address?: boolean,
   number_of_guests?: boolean,
   telephone_number?: boolean,
   package_name?: boolean,
   terms_agreement?: boolean,
   error: boolean
}

axiosRetry(axios, {
   retries: 3,
   retryDelay: (retryCount) => {
      console.log(`Error - retry attempt: ${retryCount}`)
      return retryCount * 500
   }
})

// CSS
import "./package-enquiry-styles.scss";

const PackageEnquiry: React.FC<propTypes> = ({ eventName, eventImage, title, subheading, eventPlaceholder }): JSX.Element => {
   const [enquiryData, setEnquiryData] = useState<enquiryProps>({
      first_name: "",
      last_name: "",
      email_address: "",
      number_of_guests: 2,
      telephone_number: "",
      package_name: eventName || "",
      company: "",
      message: "",
      terms_agreement: false,
      marketing_agreement: false
   })

   const [showRecaptcha, setShowRecaptcha] = useState<boolean>(false)
   const [enquiryCompleted, setEnquiryCompleted] = useState<boolean>(false)

   const [errors, setErrors] = useState<errorProps>({
      first_name: false,
      last_name: false,
      email_address: false,
      number_of_guests: false,
      package_name: false,
      telephone_number: false,
      terms_agreement: false,
      error: false
   })

   const handleUpdateData = (e: ChangeEvent): void => {
      let { name, value, checked, type } = (e.target as HTMLInputElement);

      let val: string | boolean = value;
      if(type === 'checkbox') val = checked

      setEnquiryData({
         ...enquiryData,
         [name]: val
      })

      setErrors({
         ...errors,
         error: false,
         [name]: false
      })
   }

   const handleDataValidation = (): boolean => {
      let errors_count: number = 0;
      let errors_object: { [key: string]: true } = {}

      Object.keys(enquiryData).some((key: string) => {
         let failed: boolean = false;
         // Email address
         if(key === 'email_address') {
            if(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(enquiryData[key]) === false) {
               failed = true;
            }
         // Guests #
         } else if (key === 'number_of_guests') {
            if(enquiryData[key] === null || enquiryData[key] === 0 || enquiryData[key] === "") {
               failed = true;
            }
         } else if (key === 'telephone_number') {
            if(/^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/.test(enquiryData[key]) === false) {
               failed = true;
            }
         } else if (key === 'terms_agreement') {
            if(enquiryData[key] === false) {
               failed = true;
            }
         } else if (key === 'message' || key === 'company' || key === 'marketing_agreement') {
            failed = false;
         } else if (key === 'first_name' || key === 'last_name' || key === 'package_name') {
            if(enquiryData[key].length < 2) {
               failed = true;
            }
         }

         if(failed === true) {
            errors_count++;
            errors_object[key] = true;
         }
      })

      if(errors_count === 0) {
         return true;
      } else {
         setErrors({
            ...errors,
            ...errors_object
         })

         return false;
      }
   }

   const handleLoadRecaptcha = (e: FormEvent): void => {
      e.preventDefault();

      if(handleDataValidation() === true) {
         setShowRecaptcha(true)
      }
   } 

   const handleSubmitAfterVerification = async (response: { verified: boolean, footprint?: string }): Promise<void> => {
      if(response.verified === true) {
         setShowRecaptcha(false)
         
         // Proceed to submit to server
         trackPromise(
            new Promise<boolean>( async (resolve) => {
               await axios({
                  method: 'post',
                  url: "https://api.prestige-vip.com/web/experiences/requests",
                  headers: {
                     Authorization: "Bearer " + process.env.GATSBY_SERVER_TOKEN
                  },
                  data: {
                     ...enquiryData,
                     recaptcha_footprint: response.footprint
                  }
               })
               .then((value) => {
                  const response = value.data;

                  if(response.success === true) {
                     setTimeout(() => {
                        setEnquiryCompleted(true)

                        resolve(true)
                     }, 1000)
                  } else {
                     setErrors({
                        ...errors,
                        error: true
                     })

                     resolve(false)
                  }
               })
               .catch((err) => {
                  setErrors({
                     ...errors,
                     error: true
                  })
                  
                  resolve(false)
               })
            })
         , 'submit_enquiry')
      }
   }

   return (
      <div className="package-enquiry-grid-container" id="package-enquiry">
         <div className={`package-enquiry-left ${enquiryCompleted ? 'submitted' : ''}`}>
            {
               !enquiryCompleted ? (
                  <React.Fragment>
                     <h2>{title === undefined ? 'Want to find out more?' : title}</h2>
                     <p className="secondary-text" style={{marginBottom: 30}}>{subheading === undefined ? 'Make a package enquiry' : subheading}</p>
               
                     <form>
                        {
                           eventName === undefined ? (
                              <React.Fragment>
                                 <label className={`expanded-input-wrapper plain-white ${errors.package_name ? 'error' : ''}`} htmlFor="package_name">
                                    <div className="expanded-input-content">
                                       <input
                                          id="package_name"
                                          className="expanded-input"
                                          placeholder={`e.g. ${eventPlaceholder}`}
                                          autoComplete="off"
                                          name="package_name"
                                          value={enquiryData.package_name}
                                          onChange={(e) => handleUpdateData(e)}
                                       />

                                       <label className="expanded-input-label" htmlFor="package_name">Name of event*</label>
                                    </div>
                                 </label>

                                 {
                                      errors.package_name ? (
                                       <ErrorMessage
                                          message="Please enter the name of the event you'd like to attend"
                                          topSpacing={-5}
                                       />
                                    ) : null
                                 }
                              </React.Fragment>
                           ) : (
                              <p className="secondary-text">{eventName}</p>
                           )
                        }

                        <div className="package-enquiry-form">
                           <span id="first_name_grid">
                              <label className={`expanded-input-wrapper plain-white ${errors.first_name ? 'error' : ''}`} htmlFor="first_name">
                                 <div className="expanded-input-content">
                                    <input
                                       id="first_name"
                                       className="expanded-input"
                                       placeholder="e.g. John"
                                       autoComplete="off"
                                       name="first_name"
                                       value={enquiryData.first_name}
                                       onChange={(e) => handleUpdateData(e)}
                                    />

                                    <label className="expanded-input-label" htmlFor="first_name">First name*</label>
                                 </div>
                              </label>
                           
                              {
                                 errors.first_name ? (
                                    <ErrorMessage
                                       message="Please enter your first name"
                                       topSpacing={-5}
                                    />
                                 ) : null
                              }
                           </span>

                           <span id="last_name_grid">
                              <label className={`expanded-input-wrapper plain-white ${errors.last_name ? 'error' : ''}`} htmlFor="last_name">
                                 <div className="expanded-input-content">
                                    <input
                                       id="last_name"
                                       className="expanded-input"
                                       placeholder="e.g. Smith"
                                       autoComplete="off"
                                       name="last_name"
                                       value={enquiryData.last_name}
                                       onChange={(e) => handleUpdateData(e)}
                                    />

                                    <label className="expanded-input-label" htmlFor="last_name">Last name*</label>
                                 </div>
                              </label>

                              {
                                 errors.last_name ? (
                                    <ErrorMessage
                                       message="Please enter your last name"
                                       topSpacing={-5}
                                    />
                                 ) : null
                              }
                           </span>

                           <span id="email_address_grid">
                              <label className={`expanded-input-wrapper plain-white ${errors.email_address ? 'error' : ''}`} htmlFor="email_address">
                                 <div className="expanded-input-content">
                                    <input
                                       id="email_address"
                                       className="expanded-input"
                                       placeholder="e.g. john.smith@gmail.com"
                                       autoComplete="off"
                                       name="email_address"
                                       value={enquiryData.email_address}
                                       onChange={(e) => handleUpdateData(e)}
                                    />

                                    <label className="expanded-input-label" htmlFor="email_address">Email address*</label>
                                 </div>
                              </label>

                              {
                                 errors.email_address ? (
                                    <ErrorMessage
                                       message="Please enter a valid email address"
                                       topSpacing={-5}
                                    />
                                 ) : null
                              }
                           </span>

                           <span id="number_of_guests_grid">
                              <label className={`expanded-input-wrapper plain-white ${errors.number_of_guests ? 'error' : ''}`} htmlFor="number_of_guests">
                                 <div className="expanded-input-content">
                                    <input
                                       id="number_of_guests"
                                       className="expanded-input"
                                       type="number"
                                       placeholder="e.g. 4"
                                       autoComplete="off"
                                       name="number_of_guests"
                                       value={enquiryData.number_of_guests}
                                       onChange={(e) => handleUpdateData(e)}
                                    />

                                    <label className="expanded-input-label" htmlFor="number_of_guests">No. of guests*</label>
                                 </div>
                              </label>

                              {
                                 errors.number_of_guests ? (
                                    <ErrorMessage
                                       message="# of guests"
                                       topSpacing={-5}
                                    />
                                 ) : null
                              }
                           </span>

                           <span id="telephone_number_grid">
                              <label className={`expanded-input-wrapper plain-white ${errors.telephone_number ? 'error' : ''}`} htmlFor="telephone_number">
                                 <div className="expanded-input-content">
                                    <input
                                       id="telephone_number"
                                       className="expanded-input"
                                       placeholder="e.g. 077123456789"
                                       autoComplete="off"
                                       name="telephone_number"
                                       value={enquiryData.telephone_number}
                                       onChange={(e) => handleUpdateData(e)}
                                    />

                                    <label className="expanded-input-label" htmlFor="telephone_number">Telephone number*</label>
                                 </div>
                              </label>

                              {
                                 errors.telephone_number ? (
                                    <ErrorMessage
                                       message="Please enter a valid UK phone number"
                                       topSpacing={-5}
                                    />
                                 ) : null
                              }
                           </span>

                           <label className={`expanded-input-wrapper plain-white`} id="company_grid" htmlFor="company">
                              <div className="expanded-input-content">
                                 <input
                                    id="company"
                                    className="expanded-input"
                                    placeholder="e.g. Prestige VIP Ltd"
                                    autoComplete="off"
                                    name="company"
                                    value={enquiryData.company}
                                    onChange={(e) => handleUpdateData(e)}
                                 />

                                 <label className="expanded-input-label" htmlFor="company">Company (optional)</label>
                              </div>
                           </label>

                           <label className={`expanded-input-wrapper plain-white`} id="message_grid" htmlFor="message">
                              <div className="expanded-input-content">
                                 <textarea
                                    id="message"
                                    className="expanded-input textarea"
                                    placeholder="Anything that you'd like us to know or take into account"
                                    autoComplete="off"
                                    name="message"
                                    value={enquiryData.message}
                                    onChange={(e) => handleUpdateData(e)}
                                 />

                                 <label className="expanded-input-label" htmlFor="message">Message</label>
                              </div>
                           </label>
                           
                           <span id="terms_grid">
                              <div className="terms-agreement-wrapper white">
                                 <input
                                    className="terms-agreement-input invisible"
                                    type="checkbox"
                                    id="terms-agreement"
                                    name="terms_agreement"
                                    checked={enquiryData.terms_agreement}
                                    onChange={(e) => handleUpdateData(e)}
                                 />

                                 <label className={`terms-agreement-checkbox white ${errors.terms_agreement ? 'error' : ''}`} htmlFor="terms-agreement"/>

                                 <p>I'd like to be contacted by Prestige VIP about this event, and agree to the terms laid out in the <a href="/privacy-policy" target="_blank">privacy policy</a> about how my data is treated.</p>
                              </div>

                              {
                                 errors.terms_agreement ? (
                                    <ErrorMessage
                                       message="You must accept the terms in order to proceed"
                                       topSpacing={10}
                                       bottomSpacing={5}
                                    />
                                 ) : null
                              }
                           </span>

                           <div style={{marginTop: 20}} className="terms-agreement-wrapper white" id="marketing_grid">
                              <input
                                 className="terms-agreement-input invisible"
                                 type="checkbox"
                                 id="marketing_agreement"
                                 name="marketing_agreement"
                                 checked={enquiryData.marketing_agreement}
                                 onChange={(e) => handleUpdateData(e)}
                              />

                              <label className="terms-agreement-checkbox white" htmlFor="marketing_agreement"/>

                              <p>I would like to receive further marketing and promotional material from Prestige VIP regarding other events that may be suitable to me.</p>
                           </div>
                        </div>

                        {
                           errors.error ? (
                              <ErrorMessage
                                 message="Oops, there was a technical error, please try again"
                                 topSpacing={10}
                              />
                           ) : null
                        }

                        <br/>

                        <button 
                           className="standard-button orange"
                           onClick={(e) => handleLoadRecaptcha(e)}
                        >Submit</button>
                     </form>
                  </React.Fragment>
               ) : (
                  <React.Fragment>
                     <h2>Enquiry submitted</h2>

                     <p className="secondary-text">You will now receive an email to {enquiryData.email_address} letting you know when we've received your enquiry. A member of our team will be in touch with your shortly via email or telephone to discuss availability and package options.</p>

                     <a href="/events" className="standard-button orange">See other experiences</a>
                  </React.Fragment>
               )
            }
            
         </div>

         {
            showRecaptcha ? (
               <RecaptchaPopout
                  returnResponse={handleSubmitAfterVerification}
               />
            ) : null
         }

         <FullScreenPromiseTracker
            message="Submitting enquiry"
            searchArea="submit_enquiry"
         />

         <div className="package-enquiry-image" style={{backgroundImage: `url(${eventImage})`}}/>
      </div>
   )
}

export default PackageEnquiry
