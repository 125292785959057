import React from "react";

// CSS
import "./cta-styles.scss";

const SomethingElseCta: React.FC<{ title?: string }> = ({ title }): JSX.Element => {
   return (
      <div className="something-else-cta-outer-container">
         <div className="something-else-cta-inner-container container-width">
            <h2>{title === undefined ? 'Looking for something else?' : title}</h2>
            
            <p>At Prestige VIP, we pride ourselves on our concierge-style service. There is nothing too big or small that you can request. We'll help you get the experience that you're looking for.</p>

            <a href="tel:+443333055377" className="telephone standard-button orange">0333&nbsp;305&nbsp;5377</a>
         </div>
      </div>
   )
}

export default SomethingElseCta