import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

declare global {
   namespace NodeJS {
      interface ProcessEnv {
         GATSBY_RECAPTCHA_KEY: string
      }
   }
}

const RecaptchaPopout: React.FC<{ returnResponse: (response: { verified: boolean, footprint?: string }) => void }> = ({ returnResponse }): JSX.Element => {
   const handleRobotVerification = (response: string | null): void => {
      if(response === null) {
         returnResponse({
            verified: false
         })
      } else {
         returnResponse({
            verified: true,
            footprint: response
         })
      }
   }

   return (
      <React.Fragment>
         <div className="modal-backdrop show">
            <div className="standard-modal-container">
               <h2>Are you a human?</h2>
               <p>Please pass this quick test to prove that you are not a robot.</p>

               <ReCAPTCHA
                  sitekey={process.env.GATSBY_RECAPTCHA_KEY}
                  onChange={(e) => handleRobotVerification(e)}
                  translate="yes"
                  onError={(e) => console.log(e)}
               />
            </div>
         </div>
      </React.Fragment>
   )
}

export default RecaptchaPopout