import { HeadFC } from "gatsby";
import React, { useEffect } from "react";
import CookiesConsentNotice from "../../components/cookies-consent/cookies-consent";
import SomethingElseCta from "../../components/ctas/something-else-cta";
import Footer from "../../components/footer/footer";
import Navigation from "../../components/navigation/navigation";
import PackageEnquiry from "../../components/package-enquiry/package-enquiry";
import SEO from "../../components/seo/seo";
import StylesComponent from "../../components/styles/styles-component";

const PrivateConcertExperience: React.FC = (): JSX.Element => {
   useEffect(() => {
      window.scrollTo(0, 0)
   }, [])

   return (
      <React.Fragment>
         <StylesComponent/>
         <Navigation/>
         <CookiesConsentNotice/>

         <div className="event-hero-outer-container" style={{ backgroundImage: `url(https://images.ctfassets.net/welt89o8b674/6z35KvlO4qY82BnCFyXTEo/47546e197ad7ac3e0f9fd2a148129a83/private-concert-experience.jpg)`}}>
            <div className="event-hero-container-overlay">
               <div className="event-hero-text-container container-width">
                  <h1>Private concerts</h1>
                  <p className="secondary-text">Bespoke luxury experiences</p>

                  <br/>

                  <a href="#package-enquiry" className="standard-button orange">See availability</a>
               </div>
            </div>
         </div>

         <div className="standard-event-outer-container">
            <div className="standard-event-inner-container container-width">
               <h2>Experience details</h2>

               <div className="event-details-container">
                  <div className="event-details-item">
                     <span>🗓</span>
                     <p>Enquire for availability</p>
                  </div>

                  <div className="event-details-item">
                     <span>🇬🇧</span>
                     <p>Nationwide</p>
                  </div>

                  <div className="event-details-item">
                     <span>🎤</span>
                     <p>100+ artists</p>
                  </div>

                  <div className="event-details-item">
                     <span>💵</span>
                     <p>Pricing options</p>
                  </div>
               </div>

               <div className="event-summary-wrapper">
                  <p><strong>Experience a one-of-a-kind experience with our exclusive private concerts from renowned, international artists. Impress your guests and make memories that will last a lifetime with a private performance from some of the biggest names in the music industry.</strong></p>

                  <p>From chart-topping pop stars to Grammy-winning legends, our network gives us access to musicians that will elevate any event and provide a truly unforgettable experience. Whether you're hosting a celebration, corporate gathering, or intimate concert, our team will work with you to create a personalized concert that fits your specific needs and preferences.</p>

                  <p>Indulge in the ultimate in luxury and sophistication as you sit back and enjoy an intimate performance, in an exclusive setting just for you and your guests. With a focus on detail and quality, our concerts are designed to exceed your expectations and leave you with memories that will last a lifetime.</p>
               </div>
            </div>
         </div>

         <PackageEnquiry
            eventName="Private concert availability"
            eventImage="https://images.ctfassets.net/welt89o8b674/0QjXU9QDOEwFg2zUlNN2t/b837cd90ace65d3c33bc9abb9e59491a/theater-g7cfab19e5_1280.jpg"
         />

         <SomethingElseCta/>

         <Footer/>
      </React.Fragment>
   )
}

export const Head: HeadFC = () => (
   <SEO
      title={`Private concerts | Prestige VIP`}
      metaDescription={`An experience like no other, private concerts with the world's stars, just for you. International availabilty with a variety of options.`}
      slug="/experiences/private-concerts"
   />
)

export default PrivateConcertExperience